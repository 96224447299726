import { Action } from 'redux';
import { pagesReducer, initialState as initialPageState, PageState } from './pages';
import { settingsReducer, initialState as initialSettingsState, SettingsState } from './site_settings';

// Define structure of entire redux store.
export interface AppState {
  settings: SettingsState;
  pages: PageState;
};

// Define initial state of redux.
export const defaultState: AppState = {
  settings: initialSettingsState,
  pages: initialPageState
};

/**
 * Root redux reducer. Combines all other reducers.
 *
 * @param {any} state Current state.
 * @param {AppState} action Action that was executed.
 * @returns {Action} Returns resulting state after action execution.
 */
export default function mainReducer(state: AppState = defaultState, action: Action): AppState {
  return {
    settings: settingsReducer(state.settings, action),
    pages: pagesReducer(state.pages, action)
  };
}