import { SiteSettings } from '../types/common';
import { HomePage, MembershipPage, CommunityPage, SpacePage } from '../types/pages';

import * as requester from './request-service';
import * as logger from './logging-service';

const projectId = process.env.REACT_APP_CONTENT_PROJECT_ID;
const baseUrl = `//${process.env.REACT_APP_CONTENT_BASE_URL}/${projectId}`;

interface ApiResponse<T> {
  data: T;
};

/**
 * Retrieve Settings from API.
 *
 * @returns {Promise<SiteSettings>} Returns Settings asynchronously
 */
export async function getSiteSettings(): Promise<SiteSettings> {
  try {
    if (!baseUrl) {
      throw new Error('BaseUrl not set.');
    }

    if (!projectId) {
      throw new Error('ProjectId not set.');
    }

    // NOTE: these need to match the field names in the API
    const fields = 'fields=*.*';

    const response = await requester.get<ApiResponse<SiteSettings>>(`${baseUrl}/items/site_settings?single=1&${fields}`);
    return response.data;
  }
  catch (error) {
    // TODO: handle error response
    logger.error('Directus', 'Request failure.');
    throw error;
  }
}

/**
 * Retrieve Home page from API.
 *
 * @returns {Promise<HomePage>} Returns Page asynchronously
 */
export async function getHomePage(): Promise<HomePage> {
  try {
    if (!baseUrl) {
      throw new Error('BaseUrl not set.');
    }

    if (!projectId) {
      throw new Error('ProjectId not set.');
    }

    // NOTE: these need to match the field names in the API
    const fields = 'fields=*.*,footer.*.*,products.product_id.*.*';

    const response = await requester.get<ApiResponse<HomePage>>(`${baseUrl}/items/home?single=1&${fields}`);
    return response.data;
  }
  catch (error) {
    // TODO: handle error response
    logger.error('Directus', 'Request failure.');
    throw error;
  }
}

/**
 * Retrieve Products page from API.
 *
 * @returns {Promise<MembershipPage>} Returns Page asynchronously
 */
export async function getProductsPage(): Promise<MembershipPage> {
  try {
    if (!baseUrl) {
      throw new Error('BaseUrl not set.');
    }

    if (!projectId) {
      throw new Error('ProjectId not set.');
    }

    // NOTE: these need to match the field names in the API
    const fields = 'fields=*.*,footer.*.*,products.product_id.*.*,amenities.amenities_id.*.*';

    const response = await requester.get<ApiResponse<MembershipPage>>(`${baseUrl}/items/products_page?single=1&${fields}`);
    return response.data;
  }
  catch (error) {
    // TODO: handle error response
    logger.error('Directus', 'Request failure.');
    throw error;
  }
}

/**
 * Retrieve Community page from API.
 *
 * @returns {Promise<CommunityPage>} Returns Page asynchronously
 */
export async function getCommunityPage(): Promise<CommunityPage> {
  try {
    if (!baseUrl) {
      throw new Error('BaseUrl not set.');
    }

    if (!projectId) {
      throw new Error('ProjectId not set.');
    }

    // NOTE: these need to match the field names in the API
    const fields = 'fields=*.*,footer.*.*,partner_brands.*.*';

    const response = await requester.get<ApiResponse<CommunityPage>>(`${baseUrl}/items/community_page?single=1&${fields}`);
    return response.data;
  }
  catch (error) {
    // TODO: handle error response
    logger.error('Directus', 'Request failure.');
    throw error;
  }
}

/**
 * Retrieve Space page from API.
 *
 * @returns {Promise<SpacePage>} Returns Page asynchronously
 */
export async function getSpacePage(): Promise<SpacePage> {
  try {
    if (!baseUrl) {
      throw new Error('BaseUrl not set.');
    }

    if (!projectId) {
      throw new Error('ProjectId not set.');
    }

    // NOTE: these need to match the field names in the API
    const fields = 'fields=*.*,footer.*.*';

    const response = await requester.get<ApiResponse<SpacePage>>(`${baseUrl}/items/space_page?single=1&${fields}`);
    return response.data;
  }
  catch (error) {
    // TODO: handle error response
    logger.error('Directus', 'Request failure.');
    throw error;
  }
}
