import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../reducers/root';
import { dangerouslyGenerateHTML, formatPhoneNumber } from '../../helpers';
import { PAGE_URLS } from '../../constants';

import ContactForm from '../ContactForm/ContactForm';
import CustomModal from '../../components/CustomModal/CustomModal';

import styles from './Menu.module.css';

const mapStateToProps = (state: AppState) => ({
  businessAddress: state.settings.business_address,
  phoneNumber: state.settings.contact_phone,
  emailAddress: state.settings.contact_email
});

interface MenuProps {
  showMenu: boolean;
  businessAddress?: string;
  phoneNumber?: string;
  emailAddress?: string;
  pageUrl : string;
};

const Menu: React.FC<MenuProps> = ({
  showMenu,
  pageUrl,
  businessAddress,
  phoneNumber,
  emailAddress
}) => {
  const [showContact, setShowContact] = useState(false);
  const [modal, setModal] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const menuRef = React.useRef<HTMLBodyElement>(null);
  const history = useHistory();

  const toggle = (modalState : boolean | null) => modalState !== null ?
    setModal(modalState) : setModal(!modal);

  const toggleShowContact = () => {
    setShowContact(!showContact);
  };

  const redirectToPage = (url: string) => {
    if (pageUrl != url) {
      /* eslint-disable no-undef */
      document.body.classList.remove('menu-open');
      /* eslint-enable */

      history.push(url);
    }
  };

  return (
    <>
      <CustomModal isOpen={modal} action={toggle}>
        <div className={styles.locationModal}>
          <h2>Where you can find us</h2>
          <img src={require('../../assets/images/commonplace-location.png')} />
        </div>
      </CustomModal>
      <nav ref={menuRef} className={showMenu ? `${styles.navContainer} ${styles.showMenu}` : `${styles.navContainer}`}>
        <Row>
          {isMobile && showContact ? null :
            <Col className={styles.contactDetailsContainer}>
              <div className={styles.contactDetails}>
                <span className={styles.addressLink} onClick={() => toggle(true)} dangerouslySetInnerHTML={dangerouslyGenerateHTML(businessAddress)} />

                {phoneNumber &&
                  <a href={`tel:${phoneNumber}`}>+{formatPhoneNumber(phoneNumber)}</a>
                }

                {emailAddress &&
                  <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                }
              </div>

              <ul className={styles.socialIcons}>
                <li>
                  <img />
                </li>
              </ul>
            </Col>
          }

          <Col xs='12' sm='12' md='6' className={styles.primaryMenu}>
            {!showContact ?
              <ul>
                <li className={styles.home}><button
                  onClick={() => redirectToPage(PAGE_URLS.HOME_URL)}
                  className={styles.strikeThrough}>Home</button>
                </li>
                <li className={styles.space}><button
                  onClick={() => redirectToPage(PAGE_URLS.SPACE_URL)}
                  className={styles.strikeThrough}>Space</button></li>
                <li className={styles.membership}><button
                  onClick={() => redirectToPage(PAGE_URLS.MEMBERSHIP_URL)}
                  className={styles.strikeThrough}>Membership</button></li>
                <li className={styles.community}><button
                  onClick={() => redirectToPage(PAGE_URLS.COMMUNITY_URL)}
                  className={styles.strikeThrough}>Community</button></li>
              </ul> : <ContactForm />
            }
          </Col>
        </Row>

        <div className={styles.contactSection}>
          <a>privacy policy</a>
          <button className={showContact ? `${styles.contactCta} ${styles.strikeThrough} ${styles.highlight}` : `${styles.contactCta} ${styles.strikeThrough}`} onClick={toggleShowContact}>contact us</button>
        </div>
      </nav>
    </>
  );
};

export default connect(
  mapStateToProps
)(Menu);
