import axios, { AxiosRequestConfig, Method } from 'axios';

import * as logger from './logging-service';

/**
 * Configure web client.
 *
 * @param {Method} method HTTP request method.
 * @param {string} url Url of request.
 * @param {any} data Optional request data.
 * @returns {AxiosRequestConfig} Returns client config.
 */
function config(method: Method, url: string, data?: any): AxiosRequestConfig {
  return {
    method: method,
    url: url,
    headers: { 'content-type': 'application/json' },
    data: data
  };
}

/**
 * Make a HTTP get request.
 *
 * @param {string} url Url of request.
 * @returns {Promise<T>} Returns asynchronously.
 */
export async function get<T>(url: string): Promise<T> {
  try {
    logger.log('Request', `GET: ${url}`);

    if (!url) {
      throw new Error('Url not set.');
    }

    const response = await axios.request<T>(config('get', url));

    logger.log('Request', 'Got response.', response);

    if (response.status === 200 && response.data) {
      return response.data;
    }
    else {
      throw new Error('Unknown response');
    }
  }
  catch (error) {
    // TODO: handle error response
    logger.error('Request', error);
    throw error;
  }
}
