import React from 'react';
import { dangerouslyGenerateHTML } from '../../helpers';
import { Parallax } from 'react-scroll-parallax';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';

import styles from './Header.module.css';

interface HeaderProps {
  heading?: string;
  content?: string;
  headerImage?: string;
  showMenu: boolean;
  textOverlay?: Array<string>;
  secondaryLayout?: boolean;
  customClass?: string;
  handleToggleMenu: () => void;
};

const Header: React.FC<HeaderProps> = ({
  heading,
  content,
  headerImage,
  showMenu,
  secondaryLayout,
  customClass,
  textOverlay,
  handleToggleMenu
}) => {

  const history = useHistory();

  const removeNotificationBar = () => {
    /* eslint-disable no-undef */
    document.body.classList.remove('contactSuccess');
    /* eslint-enable */
  };

  const generateHeaderContent = () => {
    if (!secondaryLayout) {
      return (
        <div className={`${styles.headerCol} ${styles.headerColFlex}`}>
          <span className={styles.growingBorder} />
          <a className={styles.ctaLink}>Join today</a>
        </div>
      );
    }
    return (
      <div className={`${styles.headerCol}`}>
        <span className={styles.secondaryContent} dangerouslySetInnerHTML={dangerouslyGenerateHTML(content)} />
      </div>
    );
  };

  return (
    <>
      <div className={styles.contactSuccessful}>
        <p>Thanks for getting in showing interest we will be in touch soon</p>
        <span onClick={() => removeNotificationBar()} className={styles.closeNotification}></span>
      </div>
      <header className={secondaryLayout ? `secondary-layout ${customClass}` : ''}>
        <div className={styles.topBar}>
          <div className={styles.logoContainer}>
            <button onClick={() => history.push('/')}>
              <img src={require('../../assets/images/logo_final_version.png')} className={styles.logoImg} />
            </button>
          </div>
          <button className={showMenu ? `${styles.menuButton} ${styles.close}` : styles.menuButton} onClick={handleToggleMenu}></button>
        </div>

        <div className={styles.headerContainer}>
          <div className={styles.headerCol}>
            <h1 dangerouslySetInnerHTML={dangerouslyGenerateHTML(heading)} />
            {!secondaryLayout ? <span className={styles.homeBlurb} dangerouslySetInnerHTML={dangerouslyGenerateHTML(content)} /> : null}
          </div>

          {generateHeaderContent()}

        </div>

        <div
          className={styles.bannerImgContainer}
          style={{ backgroundImage: `url("${headerImage}")` }}>
          <Parallax className={styles.captionLarge} y={['80px', '-80px']} >
            {textOverlay?.map((text, key) => <span key={key}>{text}</span>)}
          </Parallax>
        </div>
      </header>
    </>
  );
};

export default Header;
