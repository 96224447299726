import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../reducers/root';
import { getPage } from '../../selectors/pages';
import { PAGE_NAMES, PAGE_URLS } from '../../constants';
import { SpacePage } from '../../types/pages';

import styles from './TheSpace.module.css';

import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import TextOverlayBlock from '../../components/TextOverlayBlock/TextOverlayBlock';
import BasicTextLayout from '../../components/BasicTextLayout/BasicTextLayout';
import { setMenuActive } from '../../utilities/utilities';
import CustomModal from '../../components/CustomModal/CustomModal';

const mapStateToProps = (state: AppState) => ({
  siteSettings: state.settings,
  currentPage: getPage(state, PAGE_NAMES.SPACE) as SpacePage
});

type TheSpaceProps = ReturnType<typeof mapStateToProps>;

const TheSpace: React.FC<TheSpaceProps> = ({
  siteSettings,
  currentPage
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = (modalState : boolean | null) => modalState !== null ?
    setModal(modalState) : setModal(!modal);

  const handleToggleMenu = () => {
    setMenuActive(showMenu);
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    /* eslint-disable no-undef */
    document.body.className = '';
    document.body.classList.add('space-page');
    /* eslint-enable */
  }, []);

  if (!currentPage) {
    // TODO: handle page not found
    return null;
  }

  return (
    <>
      <CustomModal isOpen={modal} action={toggle}>
        <div className={styles.locationModal}>
          <h2>Where you can find us</h2>
          <img src={require('../../assets/images/commonplace-location.png')} />
        </div>
      </CustomModal>
      <Menu showMenu={showMenu} pageUrl={PAGE_URLS.SPACE_URL} />

      <Header
        heading={currentPage.header_title}
        content={currentPage.header_content}
        headerImage={currentPage.header_image?.data.full_url}
        showMenu={showMenu}
        handleToggleMenu={handleToggleMenu}
        secondaryLayout
        customClass={'community-header'}
        textOverlay={['Immersive', 'Considered', 'Dynamic']} />

      <BasicTextLayout
        heading={currentPage.section_a_title}
        content={currentPage.section_a_content} />

      <TextOverlayBlock
        heading={currentPage.section_b_title}
        content={currentPage.section_b_content}
        cta_label={currentPage.section_b_cta_label}
        cta_link={currentPage.section_b_cta_link}
        backgroundImage={currentPage.section_b_image?.data.full_url}
        action={toggle} />

      <Footer
        content={currentPage.footer.content}
        address={siteSettings.business_address}
        facebook_url={siteSettings.facebook_url}
        instagram_url={siteSettings.instagram_url} />
    </>
  );
};

export default connect(
  mapStateToProps
)(TheSpace);
