import React from 'react';
import { Row, Col } from 'reactstrap';

import { Icon } from '../../types/files';

import styles from './IconGrid.module.css';

interface IconGridProps {
  icons?: Icon[];
};

const IconGrid: React.FC<IconGridProps> = ({ icons }) => {
  return (
    <div className={styles.iconGridContainer}>
      <h2>Amenities.</h2>

      <Row className={styles.iconContainer}>
        {icons?.map((icon, index) =>
          <Col key={index} xs='6' sm='3' className={styles.iconBlock}>
            <img className={styles.iconImage} src={icon.image?.data.full_url} />
            <div className={styles.iconLabel}>{icon.label}</div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default IconGrid;
