import { AppState } from '../reducers/root';

import { PAGE_NAMES } from '../constants';

import { HomePage, MembershipPage, CommunityPage, SpacePage } from '../types/pages';

/**
 * Gets a specific Page from Redux.
 *
 * @param {AppState} state Redux state.
 * @param {string} pageName Page to retrieve.
 * @returns {PageState} Returns matching Page from Redux.
 */
export function getPage(
  state: AppState,
  pageName: string): HomePage | MembershipPage | CommunityPage | SpacePage | null {
  if (!state || !state.pages || !state.pages.home || !pageName) {
    return null;
  }

  switch (pageName) {
    case PAGE_NAMES.HOME:
      return state.pages.home || null;
    case PAGE_NAMES.PRODUCTS:
      return state.pages.products || null;
    case PAGE_NAMES.COMMUNITY:
      return state.pages.community || null;
    case PAGE_NAMES.SPACE:
      return state.pages.space || null;
  }

  throw new Error(`PageName '${pageName}' unknown.`);
}