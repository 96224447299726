import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../reducers/root';
import { getPage } from '../../selectors/pages';
import { PAGE_NAMES, PAGE_URLS } from '../../constants';
import { HomePage } from '../../types/pages';

import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import ProductSlider from '../../components/ProductSlider/ProductSlider';
import TextOverlayBlock from '../../components/TextOverlayBlock/TextOverlayBlock';
import BasicTextLayout from '../../components/BasicTextLayout/BasicTextLayout';
import { setMenuActive } from '../../utilities/utilities';
import CustomModal from '../../components/CustomModal/CustomModal';
import ContactForm from '../../components/ContactForm/ContactForm';

const mapStateToProps = (state: AppState) => ({
  siteSettings: state.settings,
  currentPage: getPage(state, PAGE_NAMES.HOME) as HomePage
});

type HomeProps = ReturnType<typeof mapStateToProps>;

const Home: React.FC<HomeProps> = ({
  siteSettings,
  currentPage
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = (modalState : boolean | null) => modalState !== null ?
    setModal(modalState) : setModal(!modal);

  const handleToggleMenu = () => {
    setMenuActive(showMenu);
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    /* eslint-disable no-undef */
    document.body.className = '';
    document.body.classList.add('home-page');
    /* eslint-enable */
  }, []);

  if (!currentPage) {
    // TODO: handle page not found
    return null;
  }

  return (
    <>
      <Menu showMenu={showMenu} pageUrl={PAGE_URLS.HOME_URL} />
      <CustomModal isOpen={modal} action={toggle}>
        <ContactForm
          action={toggle} />
      </CustomModal>

      <Header
        heading={currentPage.header_title}
        content={currentPage.header_content}
        headerImage={currentPage.header_image?.data.full_url}
        showMenu={showMenu}
        handleToggleMenu={handleToggleMenu}
        secondaryLayout
        customClass={'home-header'}
        textOverlay={['Beautiful', 'Affordable', 'Inspiring']} />

      <BasicTextLayout
        heading={currentPage.section_b_title}
        content={currentPage.section_b_content} />

      <TextOverlayBlock
        heading={currentPage.section_a_title}
        content={currentPage.section_a_content}
        cta_label={currentPage.section_a_cta_label}
        cta_link={currentPage.section_a_cta_link}
        backgroundImage={currentPage.section_a_image?.data.full_url} />

      <ProductSlider
        productList={currentPage.products?.map(product => product.product_id)}
        action={toggle} />

      <TextOverlayBlock
        heading={currentPage.section_c_title}
        content={currentPage.section_c_content}
        cta_label={currentPage.section_c_cta_label}
        cta_link={currentPage.section_c_cta_link}
        backgroundImage={currentPage.section_c_image?.data.full_url}
        alternate />

      <Footer
        content={currentPage.footer.content}
        address={siteSettings.business_address}
        facebook_url={siteSettings.facebook_url}
        instagram_url={siteSettings.instagram_url} />
    </>
  );
};

export default connect(
  mapStateToProps
)(Home);
