/* eslint-disable no-console */
const loggingEnabled = process.env.REACT_APP_ENABLE_LOGS || false;

/**
 * Logs a message if logging is enabled.
 *
 * @param {string} sender Source of the log call.
 * @param {any} message Message to log.
 * @param {any[]} optionalParams Optional params to log.
 */
export function log(sender: string, message: any, ...optionalParams: any[]): void {
  if (!loggingEnabled) {
    return;
  }

  console.log(`[${sender}]: ${message}`, ...optionalParams);
}

/**
 * Logs an error.
 *
 * @param {string} sender Source of the log call.
 * @param {any} message Message to log.
 * @param {any[]} optionalParams Optional params to log.
 */
export function error(sender: string, message: any, ...optionalParams: any[]): void {
  console.error(`[${sender}]: ${message}`, ...optionalParams);
}