export const setMenuActive = (menuState: boolean) => {
  if (menuState) {
    /* eslint-disable no-undef */
    document.body.classList.remove('menu-open');
    /* eslint-enable */
  }
  else {
    /* eslint-disable no-undef */
    document.body.classList.add('menu-open');
    /* eslint-enable */
  }
};