import { Action, Dispatch } from 'redux';

import * as directus from '../services/directus-service';

import { SiteSettings } from '../types/common';

export const ACTION_LOAD_SETTINGS = 'ACTION_LOAD_SETTINGS';
export const ACTION_LOAD_SETTINGS_SUCCESS = 'ACTION_LOAD_SETTINGS_SUCCESS';
export const ACTION_LOAD_SETTINGS_FAILURE = 'ACTION_LOAD_SETTINGS_FAILURE';

interface ActionLoadSettings extends Action {
  type: typeof ACTION_LOAD_SETTINGS;
}

interface ActionLoadSettingsSuccess extends Action {
  type: typeof ACTION_LOAD_SETTINGS_SUCCESS;
  payload: SiteSettings;
}

interface ActionLoadSettingsFailure extends Action {
  type: typeof ACTION_LOAD_SETTINGS_FAILURE;
  error: string;
}

export type SettingsActionTypes = ActionLoadSettings | ActionLoadSettingsSuccess | ActionLoadSettingsFailure;

/**
 * Dispatches settings load action.
 *
 * @returns {SettingsActionTypes} Returns ActionLoadHome action.
 */
function dispatchLoadSettings(): ActionLoadSettings {
  return {
    type: ACTION_LOAD_SETTINGS
  };
}

/**
 * Dispatches settings load successfully action.
 *
 * @param {SiteSettings} settings Site settings that were retrieved.
 * @returns {SettingsActionTypes} Returns ActionLoadSettingsSuccess action.
 */
function dispatchLoadSettingsSuccess(settings: SiteSettings): ActionLoadSettingsSuccess {
  return {
    type: ACTION_LOAD_SETTINGS_SUCCESS,
    payload: settings
  };
}

/**
 * Dispatches settings load failure action.
 *
 * @param {Error} e Error that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadSettingsFailure action.
 */
function dispatchLoadSettingsError(e: Error): ActionLoadSettingsFailure {
  return {
    type: ACTION_LOAD_SETTINGS_FAILURE,
    error: e.message
  };
}

/**
 * Handles action for retrieving site settings.
 *
 * @returns {Promise} Returns settings asynchronously.
 */
export function getSiteSettings() {
  return async function(dispatch: Dispatch): Promise<void> {
    try {
      dispatch(dispatchLoadSettings());

      // Make request to Directus API to retrieve Page content
      const response = await directus.getSiteSettings();

      if (response) {
        dispatch(dispatchLoadSettingsSuccess(response));
      }
    }
    catch (error) {
      dispatch(dispatchLoadSettingsError(error));
    }
  };
}