import React, { useState } from 'react';
import axios from 'axios';

import styles from './ContactForm.module.css';

interface ContactForm {
  submitText?: string;
  action?: (modalState : boolean | null) => void;
};

const API_PATH = '/contact.php';

const ContactForm: React.FC<ContactForm> = ({ submitText, action }) => {
  const [formData, updateFormData] = useState({
    name: '',
    company: '',
    email: '',
    message: '',
  });

  const formSubmit = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (formData.name && formData.company && formData.email && formData.message) {
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: formData
      })
        .then(() => {
          /* eslint-disable no-undef */
          document.body.classList.add('contactSuccess');
          window.scrollTo(0, 0);
          /* eslint-enable */
          action && action(false);
        })
        .catch(() => {
          /* eslint-disable no-undef */
          document.body.classList.add('contactFailed');
          /* eslint-enable */
        });
    }
  };

  return (
    <div className={styles.formContainer}>
      <form className={styles.contactForm}>
        <div className={styles.inputBorder}>
          <input name='name' type='text' placeholder='name' required
            onChange={(e) => updateFormData({ ...formData, name: e.target.value })} />
        </div>
        <div className={styles.inputBorder}>
          <input name='company' type='text' placeholder='company name' required
            onChange={(e) => updateFormData({ ...formData, company: e.target.value })} />
        </div>
        <div className={styles.inputBorder}>
          <input name='email' type='text' placeholder='email address' required
            onChange={(e) => updateFormData({ ...formData, email: e.target.value })} />
        </div>
        <div className={styles.inputBorder}>
          <input name='message' type='text' placeholder='tell us how we can help' required
            onChange={(e) => updateFormData({ ...formData, message: e.target.value })} />
        </div>
        <button onClick={(e) => formSubmit(e)} type='submit'>{submitText ?? 'Get in touch'}</button>
      </form>
    </div>
  );
};

export default ContactForm;
