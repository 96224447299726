// NOTE: Page names need to match
export const PAGE_NAMES = {
  HOME: 'home',
  PRODUCTS: 'products',
  COMMUNITY: 'community',
  SPACE: 'space'
};


export const PAGE_URLS = {
  HOME_URL: '/',
  MEMBERSHIP_URL: '/membership',
  COMMUNITY_URL: '/our-community',
  SPACE_URL: '/the-space'
};
