import React from 'react';

import { Product } from '../../types/products';

import styles from './ProductGridItem.module.css';

interface ProductGridItemProps {
  product: Product;
  action: (modalState : boolean | null) => void;
};

const ProductGridItem: React.FC<ProductGridItemProps> = ({ product, action }) => {
  return (
    <div className={styles.productGridItem}>
      {product.image &&
        <div className={styles.productImage} style={{ backgroundImage: `url("${product.image.data.full_url}")` }}></div>
      }

      <div className={styles.headingContainer}>
        <h3>{product.heading}</h3>
        <span className={styles.price}>{product.price}</span>
      </div>

      <ul className={styles.featuresList}>
        {product.features?.map((feature, index) =>
          <li key={index}>
            {feature.description}
          </li>
        )}
      </ul>

      <button className="contentButton" onClick={() => action(true)}>Book now</button>
    </div>
  );
};

export default ProductGridItem;
