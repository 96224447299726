import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../reducers/root';
import { getPage } from '../../selectors/pages';
import { PAGE_NAMES, PAGE_URLS } from '../../constants';

import { MembershipPage } from '../../types/pages';

//import styles from './Products.module.css';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ProductGrid from '../../components/ProductGrid/ProductGrid';
import IconGrid from '../../components/IconGrid/IconGrid';
import Menu from '../../components/Menu/Menu';
import ContactForm from '../../components/ContactForm/ContactForm';
import CustomModal from '../../components/CustomModal/CustomModal';
import { setMenuActive } from '../../utilities/utilities';

const mapStateToProps = (state: AppState) => ({
  siteSettings: state.settings,
  currentPage: getPage(state, PAGE_NAMES.PRODUCTS) as MembershipPage
});

type MembershipProps = ReturnType<typeof mapStateToProps>;

const Products: React.FC<MembershipProps> = ({
  siteSettings,
  currentPage
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = (modalState : boolean | null) => modalState !== null ?
    setModal(modalState) : setModal(!modal);

  const handleToggleMenu = () => {
    setMenuActive(showMenu);
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    /* eslint-disable no-undef */
    document.body.className = '';
    document.body.classList.add('membership-page');
    /* eslint-enable */
  }, []);

  if (!currentPage) {
    // TODO: handle page not found
    return null;
  }

  return (
    <>
      <Menu showMenu={showMenu} pageUrl={PAGE_URLS.MEMBERSHIP_URL} />
      <CustomModal isOpen={modal} action={toggle}>
        <ContactForm
          action={toggle} />
      </CustomModal>

      <Header
        heading={currentPage.header_title}
        content={currentPage.header_content}
        headerImage={currentPage.header_image?.data.full_url}
        showMenu={showMenu}
        handleToggleMenu={handleToggleMenu}
        secondaryLayout
        customClass={'home-header'}
        textOverlay={['Balance', 'Focus', 'Diverse']} />

      <ProductGrid
        products={currentPage.products?.map(product => product.product_id)}
        action={toggle} />

      <IconGrid
        icons={currentPage.amenities?.map((amenity) => {
          return {
            label: amenity.amenities_id.name,
            image: amenity.amenities_id.image
          };
        })} />

      <Footer
        content={currentPage.footer.content}
        address={siteSettings.business_address}
        facebook_url={siteSettings.facebook_url}
        instagram_url={siteSettings.instagram_url} />
    </>
  );
};

export default connect(
  mapStateToProps
)(Products);
